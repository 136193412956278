//import { Children } from "react/cjs/react.production.min";

// add id field?
export const tarotCardJson = [
   {
       "dateCompleted": "2023-07-16",
       "name": "The Fool",
       "size": "22x28",
       "tag": "major",
       "tarot-number": "00",
       "text": "The Fool starts our journey. Without experience, they make leaps of intuition, fueled by hope. They trust their abilities and the process of learning and getting back up again when they fall.  ",
       "uri": "fool-2.jpg",
    },{
        "dateCompleted": "2021-03-07",
        "name": "The Will",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "01",
        "text": "The magician creates the reality of their choosing through an act of will. They may bring others along. Question what the magician shows you, even when you are the magician.",
        "uri": "magus.jpg",
     },{
        "dateCompleted": "2020-12-26",
        "name": "The Waiting",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "02",
        "text": "Some await extraterrestials, others gods or sons or the secrets to completion. Earth is our paradise and our birthright, if only we could regain our ancestral human sense of belonging here, deeply a part of it.",
        "uri": "priestess.jpg",
     },{
        "dateCompleted": "2022-06-24",
        "name": "The Multiplier",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "03",
        "text": "Reproduction is bloody. Not reproducing is bloody. Forcing reproduction is bloodier still. Fuck male supremacy. Women are not property; People are not property. Bodily autonomy is a human right.",
        "uri": "multiplier.jpg",
     },{
        "dateCompleted": "2021-01-17",
        "name": "The Authority",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "04",
        "text": "The Authority offers order at the price of freedom. Do not give him too much. Do not forget your community when protecting your own interests. ",
        "uri": "emperor.jpg",
     },{
        "dateCompleted": "2021-05-13",
        "name": "The Guide",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "05",
        "text": "The Guide is a bridge or a waterway, of knowledge or tradition. Follow your path, it is time for seeking.",
        "uri": "hierophant.jpg",
     },{
        "dateCompleted": "2023-02-18",
        "name": "The Lover",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "06",
        "text": "The Lover is a meditation on choices, human relationships, our powers of self-determination and bodily autonomy, as well as an examination of gender roles. This arcana holds multitudes.",
        "uri": "lover.jpg",
     },{
        "dateCompleted": "2021-03-12",
        "name": "The Chariot",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "07",
        "text": "Successful action in the world.",
        "uri": "chariot.jpg",
     },{
        "dateCompleted": "2022-06-04",
        "name": "The Law",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "08",
        "text": "The Law is a human institution, prone to protecting property above people. The scales are not balanced. Injustice is the norm. Prison labor is legal slavery. The rollback of our bodily autonomy looms. So much work to do.",
        "uri": "the-law.jpg",
     },{
        "dateCompleted": "2021-04-18",
        "name": "The Hermit",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "09",
        "text": "The hermit retreats forward, their lived experience lighting the way down the path of inner wisdom and knowing themself.",
        "uri": "hermit.jpg",
     },{
        "dateCompleted": "2022-11-13",
        "name": "The Wheel",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "10",
        "text": "The Wheel is often seen as the ups and downs of fate and chance. Ours is about the eternal tug of war between authoritarian and free societal paths. Hierarchy versus mutual aid and self creation. Progress made and reactionary pushback that will never disappear but can be overpowered. Moments poised for sudden shifts as leverages shift and change follows.",
        "uri": "wheel.jpg",
     },{
        "dateCompleted": "2021-01-28",
        "name": "The Animal",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "11",
        "text": "Embrace your animal self in a collaborative and balanced partnership. Your reason tempers instinct while intuition informs reason.",
        "uri": "strength.jpg",
     },{
        "dateCompleted": "2020-12-13",
        "name": "The Inverted",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "12",
        "text": "Immobilized. Unable to move on without either gaining understanding of another's perspective or admitting your own culpability. You control this, even if you do not want to admit it.",
        "uri": "inverted.jpg",
     },{
        "dateCompleted": "2021-06-02",
        "name": "The Refiner",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "13",
        "text": "You're carrying things you no longer need. Don't worry about littering, let go and the Refiner will bury them for you. He's a skillful composter. The worms will help.",
        "uri": "death.jpg",
     },{
        "dateCompleted": "2021-02-21",
        "name": "The Temperant",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "14",
        "text": "Restore or maintain your balance and harmony. Sometimes opposites can be complementary rather than adversary.",
        "uri": "temperance.jpg",
     },{
        "dateCompleted": "2020-10-10",
        "name": "The Liberator",
        "size": "18x24",
        "tag": "major",
        "tarot-number": "15",
        "text": "An unhealthy tie binds you to addiction, bad habits or toxic people. It is time to free yourself.",
        "uri": "devil.jpg",
     },{
        "dateCompleted": "2021-04-10",
        "name": "The Realization",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "16",
        "text": "An unexpected realization changes everything. Your protective tower can be your prison. It is time to move beyond it, accepting new information or perspective.",
        "uri": "tower.jpg",
     },{
        "dateCompleted": "2020-09-19",
        "name": "The Star",
        "size": "16x20",
        "tag": "major",
        "tarot-number": "17",
        "text": "The guidance of the star is faith, but do not trust middle men or intercessors. Access your moral center, your strength, and do not be willfully blind as you seek.",
        "uri": "star.jpg",
     },{
        "dateCompleted": "2021-11-21",
        "name": "The Moon",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "18",
        "text": "The moon lights paths into our unconscious depths as well as our fears. Be brave, know yourself, accept yourself, and harness your creative self.",
        "uri": "moon.jpg",
     },{
        "dateCompleted": "2021-04-24",
        "name": "The Sun",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "19",
        "text": "Joyful and connected, the sun is Life unbounded and shared.",
        "uri": "sun.jpg",
     },{
        "dateCompleted": "2021-06-15",
        "name": "The Rebirth",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "20",
        "text": "The old self is shed. The New emerges, ready to complete the cycle of growth.",
        "uri": "rebirth.jpg",
     },{
        "dateCompleted": "2022-11-27",
        "name": "The Whole",
        "size": "22x28",
        "tag": "major",
        "tarot-number": "21",
        "text": "The Earth is the whole of our universe. Our interconnections are infinite and precious. Those who block efforts to stop warming while they talk about saving the human race by moving to Mars with their friends are not our allies. They do not care about individual lives. They do not empathize.",
        "uri": "universe.jpg",
     },{
        "dateCompleted": "2020-11-17",
        "name": "Ace of Fire",
        "size": "16x20",
        "tag": "fire",
        "tarot-number": "01",
        "text": "Unlimited creative and generative energies.",
        "uri": "fire-1.jpg",
     },{
        "dateCompleted": "2023-04-15",
        "name": "Two of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "02",
        "text": "Holding aloft the flame of hope, ambition, desire and creation. Thinking about the great deeds, the impossible accomplishments. Experiencing the hesitation of being weighed against what has already been done, offset by the determination to take your place.",
        "uri": "fire-2.jpg",
     },{
        "dateCompleted": "2021-03-18",
        "name": "Three of Fire",
        "size": "16x20",
        "tag": "fire",
        "tarot-number": "03",
        "text": "The emergence of the first plants in the Spring. The first manifestations of a creative process or cycle.",
        "uri": "fire-3.jpg",
     },{
        "dateCompleted": "2021-02-11",
        "name": "Four of Fire",
        "size": "16x20",
        "tag": "fire",
        "tarot-number": "04",
        "text": "Creation completed. The void is replaced by earth, water, air, and fire. Leaving the garden is inevitable. Prepare for expulsion.",
        "uri": "fire-4.jpg",
     },{
        "dateCompleted": "2023-01-21",
        "name": "Five of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "05",
        "text": "A fiery lake of resolve. Determination. The physical sensation of strength and the warmth of self-knowledge, the fire in your chest that illuminates the path ahead. Picking yourself up again because you have more to do and you won't be bested by this. This isn't where you end, where you are forgotten. This is how you become a fucking immortal.",
        "uri": "fire-5.jpg",
     },{
        "dateCompleted": "2022-11-29",
        "name": "Six of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "06",
        "text": "Victory. All that energy you gathered and paid out and maintained come back in a joyous riot of color. Enjoy your celebration. You've earned this.",
        "uri": "fire-6.jpg",
     },{
        "dateCompleted": "2022-12-28",
        "name": "Seven of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "07",
        "text": "Valour. Humanity reclaimed by force when there is no other option. When the time is right, PUSH. A new world may lie beyond.",
        "uri": "fire-7.jpg",
     },{
        "dateCompleted": "2021-03-15",
        "name": "Eight of Fire",
        "size": "16x20",
        "tag": "fire",
        "tarot-number": "08",
        "text": "A ship travels the void at great speed. It represents increased momentum, fast travel, and instantaneous communication.",
        "uri": "fire-8.jpg",
     },{
        "dateCompleted": "2021-08-22",
        "name": "Nine of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "09",
        "text": "The struggle has been a long one. Though the path has been one of movement both forward and back, progress is unmistakable. It may be that the end is near or perhaps it is time to pass the work on to the next courageous and tenacious person.",
        "uri": "fire-9.jpg",
     },{
        "dateCompleted": "2021-10-09",
        "name": "Ten of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "10",
        "text": "The societal divide is wider than ever before and the energy is raw and angry. Balance is lost, communication has been cut off but the outcome is not yet decided. Prepare for the worst and don’t forget the value of mutual aid.",
        "uri": "fire-10.jpg",
     },{
        "dateCompleted": "2022-09-04",
        "name": "Child of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "11",
        "text": "The child of fire is growth and potential not yet fulfilled. They are full of the joy of a thousand paths yet unexplored and the desire to become themselves.",
        "uri": "fire-child.jpg",
     },{
        "dateCompleted": "2021-11-29",
        "name": "Youth of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "12",
        "text": "The youth of fire is a creator of change. They are courageous in the face of old wrongs; speaking up, holding the front line, and making space for a wider movement to swell.",
        "uri": "youth-fire.jpg",
     },{
        "dateCompleted": "2021-07-31",
        "name": "Adult of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "13",
        "text": "The Adult of Fire brings new ideas into the world, making their marks as the neolithic painters did. They harness the human creative fire of imagination and the desire to leave some of themselves behind.",
        "uri": "fire-13-adult.jpg",
     },{
        "dateCompleted": "2020-10-11",
        "name": "Elder of Fire",
        "size": "18x24",
        "tag": "fire",
        "tarot-number": "14",
        "text": "The Elders hold a vast experience. At a time when new strategies are deeply needed, accessing our ancestral human knowledge and our most experienced guides may be the best way to move forward.",
        "uri": "fire-elder.jpg",
     },{
        "dateCompleted": "2023-01-01",
        "name": "Ace of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "01",
        "text": "The Ace of Air is the font of thought, of ideas, of conceptualizations and the abstract. Use this opportunity to immerse yourself in study or research. Take on an intellectual challenge.",
        "uri": "air-1.jpg",
     },{
        "dateCompleted": "2021-12-05",
        "name": "Two of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "02",
        "text": "Choices. Contrasting ideas.",
        "uri": "air-2.jpg",
     },{
        "dateCompleted": "2023-01-16",
        "name": "Three of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "03",
        "text": "Learn from everyone around you. Teach what you know. Listen to other's experiences and perspectives. Put your collective heads against a challenging problem. You don't have to know everything. You do have to reach out, ask questions, and share what you do know.",
        "uri": "air-3.jpg",
     },{
        "dateCompleted": "2021-07-12",
        "name": "Four of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "04",
        "text": "The mind is at rest, comfortable and stable for the short term. Gather energy to move into new growth.",
        "uri": "air-4.jpg",
     },{
        "dateCompleted": "2021-10-22",
        "name": "Five of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "05",
        "text": "When new concepts and philosophies are encountered, the mind reconsiders what it knows and gains skill and confidence.",
        "uri": "air-5.jpg",
     },{
        "dateCompleted": "2021-11-13",
        "name": "Six of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "06",
        "text": "The mind interconnected and aloft. Potential travel or the results of study and mental effort come to fruition.",
        "uri": "air-6.jpg",
     },{
        "dateCompleted": "2022-09-10",
        "name": "Seven of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "07",
        "text": "Misinformation, disinformation. Manipulation via emotion and reframing of fact, or completely making up new 'facts'. Are you being manipulated? Learn about logical fallacies and ask yourself what motivates the source of this information. How badly do you want it to be true? Are you fooling yourself?",
        "uri": "air-7.jpg",
     },{
        "dateCompleted": "2021-01-07",
        "name": "Eight of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "08",
        "text": "Interference with ideas or processes important to our core ideas and assumptions. A shift in what seems possible.",
        "uri": "air-8.jpg",
     },{
        "dateCompleted": "2021-06-30",
        "name": "Nine of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "09",
        "text": "Holding fast to values and beliefs, despite challenging circumstances or societal pressure.",
        "uri": "air-9.jpg",
     },{
        "dateCompleted": "2021-06-19",
        "name": "Ten of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "10",
        "text": "Ten white flags of surrender. Sometimes enough is enough and letting go is the only thing left to do. At least for now.",
        "uri": "air-10.jpg",
     },{
        "dateCompleted": "2021-10-21",
        "name": "Child of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "11",
        "text": "The child of air is full of wild imagings. Creatures of reality merge with those of fancy. The mind inventing forms and experimenting with possibilities.",
        "uri": "child-air.jpg",
     },{
        "dateCompleted": "2021-09-16",
        "name": "Youth of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "12",
        "text": "The youth of air examines what they know, what they've been told, and probes the gray areas lying between black and white ideas. Question everything.",
        "uri": "youth-air.jpg",
     },{
        "dateCompleted": "2020-09-26",
        "name": "Adult of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "13",
        "text": "The adult of air is a fully formed mind. Like a space station or a structure on a gas giant in the distant future, it is complex, logical and vast. It can be put to use in the service of humanity or serve merely ego.",
        "uri": "air-adult.jpg",
     },{
        "dateCompleted": "2022-01-08",
        "name": "Elders of Air",
        "size": "18x24",
        "tag": "air",
        "tarot-number": "14",
        "text": "The elders of air hold ancestral knowledge. While not gone, they are not easy to access.",
        "uri": "air-elders.jpg",
     },{
        "dateCompleted": "2021-10-19",
        "name": "Ace of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "01",
        "text": "Unlimited emotional well, empathy, joy, connection, belief in our collective possibilities. Make the most of this brief emotional bandwidth.",
        "uri": "water-1.jpg",
     },{
        "dateCompleted": "2021-05-12",
        "name": "Two of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "02",
        "text": "Emotional connection. Be real, be vulnerable; see and be seen.",
        "uri": "water-2.jpg",
     },{
        "dateCompleted": "2022-01-03",
        "name": "Three of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "03",
        "text": "Sharing our journey with one another spreads our joys and dilutes our pains. We grow when we empathize and listen to each other's stories and perspectives.",
        "uri": "water-3.jpg",
     },{
        "dateCompleted": "2022-05-12",
        "name": "Four of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "04",
        "text": "The four is about a lack of motion. It's coping mechanisms that protect us when we need them but sometimes return when we don't. It's losing access to our ability to process emotion, to become vulnerable, feel empathy, or heal. Let the four be a stop, a pause when needed. Do not stay longer then you must.",
        "uri": "water-4.jpg",
     },{
        "dateCompleted": "2022-03-07",
        "name": "Five of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "05",
        "text": "Processing trauma in order to heal. Uncovering old emotional wounds or facing fresh ones, forgiving where its warranted and letting go when possible.",
        "uri": "water-5.jpg",
     },{
        "dateCompleted": "2021-11-13",
        "name": "Six of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "06",
        "text": "Pleasure and joy emerge after the emotional work of prior cards. Six hot springs pour from the earth bearing healing minerals and invite a soak that leaves our emotional selves open and relaxed.",
        "uri": "water-6.jpg",
     },{
        "dateCompleted": "2023-05-21",
        "name": "Seven of Water",
        "size": "22x28",
        "tag": "water",
        "tarot-number": "07",
        "text": "Community care and action. Fear separates people and if there are no connection points, the fear can spread and grow out of hand. An open society must not allow the arsonists to thrive.",
        "uri": "water-7.jpg",
     },{
        "dateCompleted": "2022-02-23",
        "name": "Eight of Water",
        "size": "22x28",
        "tag": "water",
        "tarot-number": "08",
        "text": "Corruption. Rot from within poisoning the whole. The waters keep rising but those with institutional power have other concerns. Change only seems possible via a revolution of thought from below. There's a lot noise to get through.",
        "uri": "water-8.jpg",
     },{
        "dateCompleted": "2022-10-21",
        "name": "Nine of Water",
        "size": "22x28",
        "tag": "water",
        "tarot-number": "09",
        "text": "Emotional isolation at a societal scale. The land is parched. Reaching out and connecting is the only way forward.",
        "uri": "water-9.jpg",
     },{
        "dateCompleted": "2022-02-27",
        "name": "Ten of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "10",
        "text": "Collective Joy. Ephemeral experiences of wholeness and unity often felt at concerts and festivals. Remember this feeling and return to it when needed.",
        "uri": "water-10.jpg",
     },{
        "dateCompleted": "2020-10-03",
        "name": "Child of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "11",
        "text": "The child of water holds uncertainty and potential. Dreaming and sensing and hoping. Alone with themselves, creating themselves.",
        "uri": "water-child.jpg",
     },{
        "dateCompleted": "2021-05-15",
        "name": "Youth of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "12",
        "text": "The youth of water is passion and lust, an alchemical cocktail of desire to live and be and become. To experience and connect. Nothing is still or settled, all is water as fire and passion.",
        "uri": "water-youth.jpg",
     },{
        "dateCompleted": "2021-09-25",
        "name": "Adult of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "13",
        "text": "The adult of water is self aware, stable, and full of compassion for self and community.",
        "uri": "water-13-adult.jpg",
     },{
        "dateCompleted": "2021-08-21",
        "name": "Elder of Water",
        "size": "18x24",
        "tag": "water",
        "tarot-number": "14",
        "text": "The elder of water has attained universal love and feels connection and compassion for all.",
        "uri": "elder-water.jpg",
     },{
        "dateCompleted": "2022-01-25",
        "name": "Ace of Earth",
        "size": "18x24",
        "tag": "earth",
        "tarot-number": "01",
        "text": "The Ace of Earth represents boundless physical possibility. Image heavily inspired by the statues found at Çatalhöyük and the idea that they represent agricultural matriarchs, gleaned from The Dawn of Everything: A New History of Humanity by David Graeber and David Wengrow.",
        "uri": "earth-ace.jpg",
     },{
        "dateCompleted": "2021-05-29",
        "name": "Two of Earth",
        "size": "18x24",
        "tag": "earth",
        "tarot-number": "02",
        "text": "The two is about decisions. Is the grass greener on the other side?",
        "uri": "earth-2.jpg",
     },{
        "dateCompleted": "2023-03-20",
        "name": "Three of Earth",
        "size": "18x24",
        "tag": "earth",
        "tarot-number": "03",
        "text": "Creating a new vision of the worlds possible to us and sharing it with others. Transformation starts with what we can imagine together.",
        "uri": "earth-3.jpg",
     },{
        "dateCompleted": "2021-07-22",
        "name": "Four of Earth",
        "size": "18x24",
        "tag": "earth",
        "tarot-number": "04",
        "text": "The four is a comfortable place. Warm and established, perfect for growing at a comfortable pace but ultimately to be left behind when ready.",
        "uri": "earth-4.jpg",
     },{
        "dateCompleted": "2022-04-02",
        "name": "Five of Earth",
        "size": "22x28",
        "tag": "earth",
        "tarot-number": "05",
        "text": "Disruption. The stability of four is replaced with upheaval. Now is the time to seek help and provide it.",
        "uri": "earth-5.jpg",
     },{
        "dateCompleted": "2021-01-03",
        "name": "Six of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "06",
        "text": "Returns from planned work are abundant. Enjoy your fruitfulness. Share your fruitfulness.",
        "uri": "earth-6.jpg",
     },{
        "dateCompleted": "2022-04-29",
        "name": "Seven of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "07",
        "text": "Human Resources. The commodification of all earthly resources including the people. The double-bind of being in a society that expects for those above to exploit us while we take advantage of those below. We are not separate.",
        "uri": "earth-7.jpg",
     },{
        "dateCompleted": "2021-04-22",
        "name": "Eight of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "08",
        "text": "A lifetime's work completed. Generational change. Death and continuation.",
        "uri": "earth-8.jpg",
     },{
        "dateCompleted": "2021-02-26",
        "name": "Nine of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "09",
        "text": "The peak of success and joyful indulgence. A moment of celebration. You did it.",
        "uri": "earth-9.jpg",
     },{
        "dateCompleted": "2020-10-19",
        "name": "Ten of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "10",
        "text": "Destructive wealth that values only profit. Short term profit endangers long term health.",
        "uri": "earth-10.jpg",
     },{
        "dateCompleted": "2022-04-24",
        "name": "Child of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "11",
        "text": "The child of earth is at home. They are trusting, grounded and at peace. When the time comes, they will know what to do.",
        "uri": "earth-child.jpg",
     },{
        "dateCompleted": "2022-10-22",
        "name": "Youth of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "12",
        "text": "The youth of earth is industrious and productive but remains in harmony with their world. You will know where they have been at work, improving, beautifying or just caring.",
        "uri": "earth-youth.jpg",
     },{
        "dateCompleted": "2020-11-22",
        "name": "Adult of Earth",
        "size": "16x20",
        "tag": "earth",
        "tarot-number": "13",
        "text": "The adult of earth is the planet's own self-generation. Tectonic plates are subsumed and fresh lava emerges. The eternal planet witnesses birth and death of life forms inumerable.",
        "uri": "earth-adult.jpg",
     },{
        "dateCompleted": "2021-09-08",
        "name": "Elders of Earth",
        "size": "18x24",
        "tag": "earth",
        "tarot-number": "14",
        "text": "The elders of earth care for the world and its inhabitants and interconnections. They work to return the living planet to health.",
        "uri": "elder-earth.jpg",
     }
]